<template>
  <form class="login" @submit.prevent="onsubmit">
    <div class="d-flex justify--center my-3 " >
      <Vtest style="max-width: 60%; background-color: #ffffff;" />
    </div>
    <div class="d-flex justify--center mt-3" v-if="overallError !== ''">
      <p class="overAllEerror"> {{overallError}} </p>
    </div>
    <va-input
      v-model="email"
      :label="$t('auth.email')"
      :error="!!emailErrors.length"
      :error-messages="emailErrors"
      :disabled="stopBTn"
      removable
    />

    <va-input
      v-model="password"
      type="password"
      :label="$t('auth.password')"
      :error="!!passwordErrors.length"
      :error-messages="passwordErrors"
      :disabled="stopBTn"
      passwordView
      removable
    />

    <div class="d-flex justify--center mt-5">
      <button type="submit"  :disabled="stopBTn" class="my-0 recoverLink">{{ $t('auth.login') }}</button>
    </div>
  </form>
</template>

<script>
import firebase from 'firebase'
import Vtest from '../../../iconset/Vtest'

export default {
  name: 'login',
  components: { Vtest },

  data () {
    return {
      email: '',
      password: '',
      keepLoggedIn: false,
      stopBTn: false,
      emailErrors: [],
      passwordErrors: [],
      overallError: '',
    }
  },
  created () {
    document.title = this.$t('generic.Leap') + '-' + this.$t('auth.login')

    if (this.$store.state.status.loggedIn && !this.$store.state.status.mustChangePass) {
      this.$router.push({ name: 'google-maps' })
    }
    if (this.$store.state.status.loggedIn && this.$store.state.status.mustChangePass) {
      this.$router.push({ name: 'recover-password' })
    }
  },
  computed: {
    formReady () {
      return !this.emailErrors.length && !this.passwordErrors.length
    },
  },
  watch: {
    email: function (val) {
      this.stopBTn = false
    },
    password: function (val) {
      this.stopBTn = false
    },
  },
  methods: {
    onsubmit () {
      this.stopBTn = true
      this.overallError = ''
      this.emailErrors = this.email ? [] : ['Email is required']
      this.passwordErrors = this.password ? [] : ['Password is required']
      if (!this.formReady) {
        this.stopBTn = false

        return
      }
      if (this.email && this.password) {
        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password) // THIS LINE CHANGED
          .then((response) => {
            console.log('Successfully logged in!')
            localStorage.setItem('user', JSON.stringify(response.user))
            this.$store.dispatch('login', response.user)
            this.$router.push({ name: 'dashboard' })
            localStorage.removeItem('mustlogin')
            localStorage.removeItem('themesColors')
            this.stopBTn = false
          })
          .catch(error => {
            console.log(error.code)
            switch (error.code) {
            case 'auth/invalid-email':
              this.overallError = 'Invalid email'
              break
            case 'auth/user-not-found':
              this.overallError = 'No account with that email was found'
              break
            case 'auth/wrong-password':
              this.overallError = 'Incorrect password'
              break
            default:
              this.overallError = 'Email or password was incorrect'
              break
            }

            this.stopBTn = false
          })
      }
    },
  },
}
</script>

<style lang="scss">

.recoverLink {
  color: #ff512f;
  padding: 10px 0;
  border: 1px solid;
  border-radius: 4px;
  width: 100%;
}

.va-input__container {
  border-radius: 4px;
}

.va-input__container__label {
  color: #ff512f !important;
}

.overAllEerror {
  color: red;
}

.login {
  height: 520px;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.13);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding: 50px 35px;
}
</style>
